import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"
import RequestAPhrase from "../components/RequestAPhrase"

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta pageTitle="Languages" />
      <PageHeader>Languages</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Languages", href: "/language/" },
        ]}
      />

      <BrowseList
        items={data.allLanguagesJson.nodes}
        placeholderPrefix="Enter language"
        sortAlpha={true}
      />

      <RequestAPhrase />
    </Layout>
  )
}

export const query = graphql`
  query LanguageIndexQuery {
    allLanguagesJson(filter: { fields: { name: { ne: "" } } }) {
      nodes {
        fields {
          name
          slug
        }
      }
    }
  }
`
